import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded/imagesloaded.pkgd';

let mobileMenu = document.querySelector('.nav-mobile');
let mobileMenuToggle = document.querySelector('.nav-mobile-toggle');
let mobileMenuClose = document.querySelector('.nav-mobile-close');

mobileMenuToggle.addEventListener('click', function() {
    mobileMenu.classList.toggle('open');
});

mobileMenuClose.addEventListener('click', function() {
    mobileMenu.classList.remove('open');
})

let imageGridEl = document.querySelector('.module-media-library.widget-media-library-lightbox .widget-body');
if (imageGridEl) {
    imagesLoaded(imageGridEl, function() {
        new Masonry(
            imageGridEl,
            {
                itemSelector: 'figure',
                gutter: 10
            }
        )
    })
}

let inputs = Array.from(document.querySelectorAll('input, select, textarea'));
inputs.map(input => {
    input.addEventListener('invalid', (e) => {
        //-- Remove previous error
        let previousError = e.target.parentElement.querySelector('.error')
        if (previousError) {
            e.target.parentElement.removeChild(previousError);
        }

        let data = e.target.dataset;
        let errorIndex = Object.keys(data).findIndex(prop => {
            return ~prop.indexOf('error')
        });

        if (errorIndex !== -1) {
            let error = Object.values(data)[errorIndex];

            let div = document.createElement('div');
            div.innerText = error;
            div.classList.add('error');
            e.target.parentElement.appendChild(div);
        }
    })

    input.addEventListener('blur', (e) => {
        //-- Remove previous error
        let previousError = e.target.parentElement.querySelector('.error')
        if (previousError) {
            e.target.parentElement.removeChild(previousError);
        }
    })
});